import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

import { SeguridadService } from '@services/index';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule,RouterOutlet, MatButtonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  timeoutObject:any;
  title = 'Shopology Web';
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private seguridadService = inject(SeguridadService);

  @HostListener('mousemove', ['$event'])
  onMouseMove(e:any) {

    clearTimeout(this.timeoutObject);
    this.ejecutarConteo();

  }

  constructor(){}

  ngOnInit(): void {
    try {
      this.ejecutarConteo();
    } catch (error:any) {
      Swal.fire(error.name + ': "' + error.message +  '"', '', 'error');
    }
  }

  ejecutarConteo(){
    try {
      if(!this.seguridadService.estaLogueado()){
        return;
      }
      let timerInterval:any;

      this.timeoutObject = setTimeout(() => {

          Swal.fire({
            title: '',
            html: 'Se cerrará la sesión en <b></b>.',
            icon: 'warning',
            showCancelButton: true,
            timer: 10000,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Esperar',
            cancelButtonText: 'Cancelar',
            didOpen: () => {
              const b:any = Swal.getHtmlContainer()?.querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = (Swal.getTimerLeft()! / 1000).toFixed(0);
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
              if (result.isConfirmed) {
                this.toastr.info("Se ha cancelado el cierre de sesión", "",{
                  timeOut: 3000
                });
              } else if (result.dismiss === Swal.DismissReason.timer || result.dismiss === Swal.DismissReason.cancel) {
                  this.toastr.info("Se ha cerrado la sesión", "",{
                    timeOut: 3000
                  });
                this.logout();
              }
          })
      }, 3600000);
    } catch (error:any) {
      Swal.fire(error.name + ': "' + error.message +  '"', '', 'error');
    }
  }

  logout(){
    try {
      this.seguridadService.logout();
      this.router.navigateByUrl('login');
    } catch (error:any) {
      Swal.fire(error.name + ': "' + error.message +  '"', '', 'error');
    }
  }
}
